<template>
    <component
        :is="tag"
        :class="[
            `base-icon--is-${size}`,
            `base-icon--is-${color}`
        ]"
        class="base-icon"
        :disabled="isDisabled"
        :data-testid="`${name}`"
        @click="onClick"
    >
        <span
            :class="[ background !== 'none' ? 'base-icon--has-background' : null ]"
            class="base-icon__wrap"
            :style="`background: ${background}`"
            v-html="require(`!html-loader!@RepoAsset/icons/icon-${name}.svg`)"
        >
        </span>
        <span
            v-if="$slots['label']"
            class="base-icon__label">
            <slot name="label"></slot>
        </span>
    </component>
</template>

<script>

    export default {
        name: 'BaseIcon',

        props: {
            name: {
                type: String,
                required: true
            },
            size: {
                type: String,
                default: 'small'
            },

            color: {
                type: String,
                default: 'light'
            },

            background: {
                type: String,
                default: 'none'
            },

            tag: {
                type: String,
                default: 'div'
            },

            isDisabled: {
                type: Boolean,
                required: false
            }
        },

        methods: {
            onClick() {
                this.$emit('onClick');
            }
        }
    };
</script>

<style lang='scss' scoped src='./BaseIcon.scss'></style>
